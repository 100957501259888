<template>
	<div id="wrapper" class="hidden">
		<header>
			<div id="mainWrapper">
				<h1><img src="./assets/logo.png" :alt="title"></h1>
				<h2>癒しのヴェールで世界を包む至高のアーティスト<span>日本で特別展を開催</span></h2>
				<img src="./assets/main-visual.png" :alt="title">
			</div>
			<Novelty></Novelty>
		</header>
		  <Artist></Artist>
		  <Gallery></Gallery>
		  <Noveltyinfo></Noveltyinfo>
		  <Schedule></Schedule>
		  <Footer></Footer>
		  <Extra></Extra>
	  </div>
</template>

<script>
import Novelty from './components/novelty.vue'
import Artist from './components/artist.vue'
import Gallery from './components/gallery.vue'
import Noveltyinfo from './components/noveltyinfo.vue'
import Schedule from './components/schedule.vue'
import Footer from './components/footer.vue'
import Extra from './components/extra.vue'

import axios from 'axios'
export default {
  name: 'h1',
  data () {
    return {
		title: 'カーク・レイナート展',
		widths: window.innerWidth
	}
  },
  methods: {
  },
  created: function() {
  },
  beforeDestroy: function () {
  },
  components: {
    Novelty,
	Artist,
	Gallery,
	Noveltyinfo,
	Schedule,
	Footer,
	Extra
  }
}
</script>
<style lang="scss">
$main : #6e6307;
$maintint : #b39f47;
@mixin whiteshadow {
	text-shadow:
		0 0 4px #fff,
		0 0 4px #fff,
		0 0 4px #fff,
		0 0 4px #fff,
		0 0 4px #fff;
}
@mixin transform ($transValue) {
	-o-transform: $transValue;
	-webkit-transform: $transValue;
	-moz-transform: $transValue;
	transform: $transValue;
}

@keyframes opacityAnimation {
	0%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}
@-webkit-keyframes opacityAnimation {
	0%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}
@-moz-keyframes opacityAnimation {
	0%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}

header #mainWrapper h1 img {
	opacity: 0;
	animation: opacityAnimation 2s 1s forwards;
	-webkit-animation-name: opacityAnimation;
	-webkit-animation-duration: 2s;
	-webkit-animation-delay: 1s;
	-webkit-animation-fill-mode: forwards;
}

header #mainWrapper h2 {
	opacity: 0;
	animation: opacityAnimation 1s 1.8s forwards;
	-webkit-animation-name: opacityAnimation;
	-webkit-animation-duration: 1s;
	-webkit-animation-delay: 1.8s;
	-webkit-animation-fill-mode: forwards;
}

.hiddenOver {
	display: block;
	animation: opacityAnimation 2s forwards;
	-webkit-animation-name: opacityAnimation;
	-webkit-animation-duration: 2s;
	-webkit-animation-fill-mode: forwards;
}

#loading {
	background: #fff;
	height: 100vh;
	width: 100%;
	position: relative;
	overflow: hidden;
}
#loading img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
		
.hidden {
display: none;
}


#wrapper {
    background: url(./assets/bg-type01.png) no-repeat;
    background-size: 100%;
    background-position: 0px 80px;
}
header {
	position: relative;
	width: 100%;
	#mainWrapper {
		margin: 0 auto;
		overflow: hidden;
			&>img {
				width: 104%;
				margin: -2% -2% 0;
				}
            h1 {
                position: absolute;
                width: 86%;
                top: 6%;
                left: calc(( 100% - 86% ) / 2 );
				img {
					width: 100%;
				}
                }
			h2 {
				@include whiteshadow;
				position: absolute;
				top: 18%;
				margin: 0 auto;
				left: 0;
				right: 0;
				text-align: center;
				font-size: 3.75vw;
				letter-spacing: .1rem;
				width: 92%;
				span {
					text-align: right;
					display: block;
					width: 92%;
					line-height: 2;
					margin: 0 auto;
				}
			}
		}
	}
@media only screen and (min-width:768px) {
	#wrapper {
		background: url(./assets/bg-type01-pc.png) no-repeat;
		background-size: 100%;
		background-position: top;
	}
	header {
		#mainWrapper {
			&>img {
				width: 60%;
    			height: auto;
				object-fit: cover;
				margin: 0;
				float: right;
			}
		}
	}
}
@media only screen and (min-width:1024px) {
	header {
	height: 100vh;
		#mainWrapper {
			&>img {
				position: absolute;
				top: 50%;
				@include transform(translateY(-50%));
				right: 0;
			}
		}
	}
}
@media only screen and (min-width:1200px) {
	header {
		#mainWrapper {
			&>img {
				width: 55%;
				}
		}
	}
}
@media only screen and (min-width:1350px) {
	header {
		width: 90%;
		margin: 0 auto;
	}
}
@media only screen and (min-width:1499px) {
	header {
		width: 80%;
	}
}
</style>





