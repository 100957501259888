<template>
		<section id="gallery">
			<h1><span></span>Gallery<span></span></h1>
			<h2>ベイビーアニマルズシリーズとフェアリーシリーズ。<span></span>カークが描く2つの世界。</h2>
			<section class="innerGallery">
			<div>
				<figure><img src="../assets/gallery-pic01-f1.jpg" alt="サマーラブ/Summer Love">
				<figcaption>Baby Animals Series</figcaption>
				</figure>
				<ul>
					<li><img src="../assets/gallery-pic02.jpg" alt="ファジーラブ/Fuzzy Love"></li>
					<li><img src="../assets/gallery-pic03.jpg" alt="スリーベイビーバニー&amp;オーロラ/3 Baby Bunny&amp;Aurora"></li>
				</ul>
			</div>
				<div>
				<h3>Baby Animals Series</h3>
				<p>ベイビーアニマルズシリーズは、オーロラに抱かれた動物たちが集い憩う愛くるしさで、長年人気を集めています。<br>
					ふわりとした毛並みを纏い、希望と優しさに満ちた表情で、鑑賞者を惹き込む動物たち。<br>
					何者にも染まっていない純真無垢な心を描いた、カークの代表シリーズです。</p>
				</div>
			</section>
			<section class="innerGallery">
			<div>
				<figure><img src="../assets/gallery-pic04.jpg" alt="ファーストライト/First Light">
				<figcaption>Fairies Series</figcaption>
				</figure>
				<ul>
					<li><img src="../assets/gallery-pic05.jpg" alt="マジェスティック/Majestic"></li>
					<li><img src="../assets/gallery-pic06.jpg" alt="サクラムーン/Sakura Moon"></li>
				</ul>
			</div>
				<div>
				<h3>Fairies Series</h3>
				<p>フェアリーシリーズは、幻獣や妖精たちの聖域を描き、男女問わず高く評価されています。<br>
					神話の世界より気高く大地に立つ幻獣、花の様に可憐かつ優雅に舞い降りる妖精たち。<br>
					カークが魅せる、人々の想像を超えた神秘に触れることができるシリーズです。</p>
				</div>
			</section>
		</section>
</template>
 
<script>
export default {
  data() {
    return {
    };
  },
  methods: {
    handleScroll() {
		var allHeight = window.innerHeight;
		var scroll = window.pageYOffset;
		var targetPosition04 = window.pageYOffset + document.querySelector('#gallery h1').getBoundingClientRect().top;
		var targetPosition05 = window.pageYOffset + document.querySelector('#gallery h2').getBoundingClientRect().top;
		var targetPosition06 = window.pageYOffset + document.querySelector('.innerGallery:first-of-type figure figcaption').getBoundingClientRect().top;
		var targetPosition07 = window.pageYOffset + document.querySelector('.innerGallery:first-of-type div:nth-of-type(2) > p').getBoundingClientRect().top;
		var targetPosition08 = window.pageYOffset + document.querySelector('.innerGallery:nth-of-type(2) figure figcaption').getBoundingClientRect().top;
		var targetPosition09 = window.pageYOffset + document.querySelector('.innerGallery:nth-of-type(2) div:nth-of-type(2) > p').getBoundingClientRect().top;
		var targetPositionPC02 = window.pageYOffset + document.querySelector('.innerGallery:first-of-type div:nth-of-type(2)').getBoundingClientRect().top;
		var targetPositionPC03 = window.pageYOffset + document.querySelector('.innerGallery:nth-of-type(2) div:nth-of-type(2)').getBoundingClientRect().top;
	  
      if (scroll > targetPosition04 - allHeight) {
       document.querySelector('#gallery h1').classList.add('galleryAni01');
      }
      if (scroll > targetPosition05 - allHeight) {
       document.querySelector('#gallery h2').classList.add('galleryAni02');
      }
      if (scroll > targetPosition06 - allHeight) {
       document.querySelector('.innerGallery:first-of-type figure figcaption').classList.add('galleryAni03');
      }
      if (scroll > targetPosition07 - allHeight) {
       document.querySelector('.innerGallery:first-of-type div:nth-of-type(2) > p').classList.add('galleryAni01');
      }
      if (scroll > targetPosition08 - allHeight) {
       document.querySelector('.innerGallery:nth-of-type(2) figure figcaption').classList.add('galleryAni03');
      }
      if (scroll > targetPosition09 - allHeight) {
       document.querySelector('.innerGallery:nth-of-type(2) div:nth-of-type(2) > p').classList.add('galleryAni01');
      }
      if (scroll > targetPositionPC02 - allHeight) {
       document.querySelector('.innerGallery:first-of-type div:nth-of-type(2)').classList.add('galleryAni04');
      }
      if (scroll > targetPositionPC03 - allHeight) {
       document.querySelector('.innerGallery:nth-of-type(2) div:nth-of-type(2)').classList.add('galleryAni04');
      }
    }
  },
  created: function() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy: function () {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>
<style lang="scss" scoped>
@media only screen and (min-width:768px) {
.galleryAni04 {
	opacity: 0;
	animation: artistSlideAnimation 1.5s .5s forwards;
	-webkit-animation-name: artistSlideAnimation;
	-webkit-animation-duration: 1.5s;
	-webkit-animation-delay: .5s;
	-webkit-animation-fill-mode: forwards;
}
}
</style>
<style lang="scss">

@keyframes slideAnimation {
	0%{
		margin-left: 7%;
	}
	100%{
		opacity: 1;
		margin-left: 0;
	}
}
.galleryAni01 {
	animation: opacityAnimation 2s .8s forwards;
	-webkit-animation-name: opacityAnimation;
	-webkit-animation-duration: 2s;
	-webkit-animation-delay: .8s;
	-webkit-animation-fill-mode: forwards;
}
.galleryAni02 {
	animation: opacityAnimation 2s 1.5s forwards;
	-webkit-animation-name: opacityAnimation;
	-webkit-animation-duration: 2s;
	-webkit-animation-delay: 1.5s;
	-webkit-animation-fill-mode: forwards;
}
.galleryAni03 {
	animation: slideAnimation 2s 1s forwards;
	-webkit-animation-name: slideAnimation;
	-webkit-animation-duration: 2s;
	-webkit-animation-delay: 1s;
	-webkit-animation-fill-mode: forwards;
}

$main : #6e6307;
$maintint : #b39f47;
@mixin transform ($transValue) {
	-o-transform: $transValue;
	-webkit-transform: $transValue;
	-moz-transform: $transValue;
	transform: $transValue;
}
@mixin maincolorshadow {
	box-shadow:
		0 0 10px $maintint;
}
@mixin maintintboxshadowmin {
	box-shadow:
		0 0 5px $maintint;
}
.innerGallery div h3 {
	display: none;
}
#gallery {
	position: relative;
    background: url(../assets/bg-type03.png) no-repeat;
    background-size: 100%;
    background-position: center;
	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 3vh;
		background: url(../assets/gallery-frame01.png), url(../assets/gallery-frame02.png), url(../assets/gallery-frame03.png), url(../assets/gallery-frame04.png);
		background-size: 20%,20%,20%,20%;
		background-repeat: no-repeat,no-repeat,no-repeat,no-repeat;
		background-position: left top,right top,left bottom,right bottom;
	}
	h1 {
		opacity: 0;/*default*/
		font-family: 'Italianno', cursive;
		color: $maintint;
		font-size: calc(3.4rem + ((1vw - 0.64rem) * 2.1429));
		letter-spacing: .5vw;
		text-align: center;
		span::before {
			content: '';
			font-family: 'Italianno', cursive;
			writing-mode: vertical-lr;
			background: url(../assets/flower-type02.png);
			background-repeat: no-repeat;
			width: 48px;
			height: 18px;
			background-size: cover;
			display: inline-block;
			vertical-align: sub;
			margin: 0 .5rem;
		}
		span:nth-of-type(2)::before {
			transform: scale(-1, 1);
		}			
	}
	h2 {
		opacity: 0;/*default*/
		text-align: center;
		font-size: calc(1.5rem + ((1vw - 0.64rem) * 2.1429));
		margin-top: 1vh;
		&>span {
			display: block
		}
	}
}

.innerGallery {
		width: 88vw;
		margin: 3vh 6vw;
	figure {
		img {
			width: 100%;
			@include maincolorshadow;
			border: 3px #fff solid;
		}
		figcaption {
			opacity: 0;/*default*/
			margin-left: 7%;/*default*/
			font-family: 'Italianno', cursive;
			font-size: calc(3.4rem + ((1vw - 0.64rem) * 2.1429));
			color: $maintint;
			padding: .5vh 0;
					&::after{
						content: 'Baby Animals Series';
						font-family: 'Italianno', cursive;
						color: $maintint;
						-webkit-filter: blur(2px);
						filter: blur(2px);
						font-size: 7vw;
						opacity: .3;
						display: block;
						margin-top: -3rem;
						white-space: nowrap;
						overflow: hidden;
						text-align: right;
					}	
		}
	}
	ul {
		li {
			width: 49%;
			float: left;
			/*height: 100%;*/
			height: auto;
			padding: 0 0 2vh;
			img {
				width: 100%;
				@include maintintboxshadowmin;
				border: 1px #fff solid; 
			}
			&:nth-of-type(2) {
				float: right;
			}
		}
	}
	div:nth-of-type(2) {
	clear: both;/*add*/
    &>p {
		opacity: 0;/*default*/
        line-height: 1.8;
        clear: both;
		font-size: calc(1.72rem + ((1vw - 0.64rem) * 2.1429));
    }
	}
}

.innerGallery:nth-of-type(2) {
	margin-top: 6vh;
    padding-bottom: 6.5vw;
	figcaption {
      &::after{
          content: 'Fairies Series';
      }
	}
}
@media only screen and (min-width:768px) {
figcaption {
	display: none;
}
.innerGallery div h3 {
	display: block;
}
#gallery {
	width: 100%;
    clear: both;
	margin: -5% auto 0;
    background: url(../assets/bg-type03-pc.png) no-repeat;
    background-size: 100% 100%;
    background-position: center;
    mask-image: url(../assets/bg-type03-pc-mask.png);
                -webkit-mask-image:url(../assets/bg-type03-pc-mask.png);
                -webkit-mask-size: cover;
		&::before {
			background: none;
		}
		h1 {
    		letter-spacing: .2vw;
		}
		h2 {
			font-size: 1.1rem;
		}
}
.innerGallery {
	width: 100%;
	margin: 2rem 0;
	overflow: hidden;
	figure {
		width: 46.5%;
		float: left;
		margin-left: 2%;
		position: relative;
		z-index: 5;
	}
	ul {
		width: 48%;
		float: right;
		margin-right: 2%;
		position: relative;
		z-index: 5;
		li {
			img {
				border: 3px #fff solid;
			}
		}
	}
	div:nth-of-type(2){
	opacity: 0;/*default*/
	overflow: hidden;
	width: 90%;
	float: right;

	padding: 4% 0 4% 4%;
	margin-top: -5%;
	position: relative;
	z-index:2;
	&::before {
		content: '';
		background-color: rgba(255,255,255,0.7);
		clip-path: polygon(23% 0, 100% 0, 100% 100%, 0 100%);
		border: 1px solid #eee;
		width: 100%;
		height: 100%;
		top: -5%;
		position: absolute;
		z-index: 1;
	}
		h3 {
			font-family: 'Italianno', cursive;
			font-size: 3rem;
			color: $maintint;
			padding: .5rem 0;
			position: relative;
			z-index: 10;
				  &::after{
					  content: 'Baby Animals Series';
					  font-family: 'Italianno', cursive;
					  color: $maintint;
					  -webkit-filter: blur(2px);
					  filter: blur(2px);
					  font-size: 3.5rem;
					  opacity: .3;
					  display: block;
					  margin-top: -5rem;
					  white-space: nowrap;
					  overflow: hidden;
					  margin-left: 16%;
					  position: relative;
					  z-index: 9;
				  }
		}
		&>p {
			position: relative;
			z-index: 10;
			font-size: 1.1rem;
			width: auto;
			float: right;
			margin-right: 4%;
		}
	}
}
.innerGallery:nth-of-type(2) {
	div h3::after {
          content: 'Fairies Series';
      }
	 ul {
	 	float: left;
		    margin-left: 2%;
    margin-right: 0;
	 }
	 div figure {
	 	float: right;
		    margin-right: 2%;
    margin-left: 0;
	 }
}
}
@media only screen and (min-width:500px) {
	#gallery h1,
	.innerGallery figure figcaption {
		font-size: 9vw;
	}
}
@media only screen and (min-width:600px) {
	#gallery h1,
	.innerGallery figure figcaption {
		font-size: 8vw;
	}
	#gallery h1 span::before {
		width: 60px;
		height: 23px;
	}
}
@media only screen and (min-width: 768px) {
	#gallery h1 span::before {
		width: 80px;
		height: 30px;
	}
	#gallery h1,
	.innerGallery figure figcaption {
		font-size: 3rem;
	}
	#gallery h1 span::before {
		width: 48px;
		height: 18px;
	}
}
@media only screen and (min-width:1800px) {
.innerGallery{
	div:first-of-type{
		width: 90%;
		margin: 0 auto;
	}
	div:nth-of-type(2) > p {
	margin-right: 10%;
	}
}

}


</style>



