<template>
<article id="schedule">
<h1><span></span>Event Information<span></span></h1>
<div id="trigger" class="dateScheduleWrapper">
<ul class="dateSchedule" :style="{ '-webkit-flex-direction': 'column' }">
</ul>
</div>
</article>
</template>
 
<script>
export default {
  data() {
    return {
    };
  },
  methods: {
    handleScroll() {
		var allHeight = window.innerHeight;
		var scroll = window.pageYOffset;
		var targetPosition12 = window.pageYOffset + document.querySelector('#schedule h1').getBoundingClientRect().top;
	  
      if (scroll > targetPosition12 - allHeight) {
       document.querySelector('#schedule h1').classList.add('galleryAni01');
      }
    }
  },
  created: function() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy: function () {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>
<style lang="scss">
$main : #6e6307;
$maintint : #b39f47;
@mixin whiteshadow {
	text-shadow:
		0 0 4px #fff,
		0 0 4px #fff,
		0 0 4px #fff,
		0 0 4px #fff,
		0 0 4px #fff;
}
@mixin maincolorshadow {
	box-shadow:
		0 0 10px $maintint;
}
@mixin maintintboxshadowmin {
	box-shadow:
		0 0 5px $maintint;
}
@mixin whiteshadow2 {
	text-shadow: -1px -1px #fff,1px -1px #fff,-1px 1px #fff,1px 1px #fff;
}
@mixin flex {
	display: flex;
	display: -webkit-flex;
}
@mixin reflect {
	-webkit-box-reflect: below 1px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(90%, transparent), to(rgba(255,255,255,0.5)));
}
@mixin redBtnFull {
	color: #fff;
	text-align: center;
	width: 100%;
	@include radius(5px);
	font-size: 20px;
	padding: 6px 5px;
	border: 1px #630606 solid;
	text-shadow: 0px 1px 0px black;
	background: -moz-linear-gradient(90deg, rgba(99,6,6,1) 0%, rgba(153,15,12,1) 99%, rgba(153,15,12,1) 100%); /* ff3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(153,15,12,1)), color-stop(1%, rgba(153,15,12,1)), color-stop(100%, rgba(99,6,6,1))); /* safari4+,chrome */
	background: -webkit-linear-gradient(90deg, rgba(99,6,6,1) 0%, rgba(153,15,12,1) 99%, rgba(153,15,12,1) 100%); /* safari5.1+,chrome10+ */
	background: -o-linear-gradient(90deg, rgba(99,6,6,1) 0%, rgba(153,15,12,1) 99%, rgba(153,15,12,1) 100%); /* opera 11.10+ */
	background: -ms-linear-gradient(90deg, rgba(99,6,6,1) 0%, rgba(153,15,12,1) 99%, rgba(153,15,12,1) 100%); /* ie10+ */
	background: linear-gradient(0deg, rgba(99,6,6,1) 0%, rgba(153,15,12,1) 99%, rgba(153,15,12,1) 100%); /* w3c */
	display: block;
}
@mixin opacity($alpha) {
     opacity: $alpha;
	 -moz-opacity: $alpha;
     $alphaIE: $alpha * 100;
     filter: alpha(opacity=$alphaIE);
}
@mixin radius($value) {
	moz-border-radius: $value;
	webkit-border-radius: $value;
	border-radius: $value;
}
@mixin boxShadow($shadows) {
	box-shadow: $shadows;
	-moz-box-shadow: $shadows;
	-webkit-box-shadow: $shadows;
}
@mixin transition ($transValue) {
	-o-transition: $transValue;
	-webkit-transition: $transValue;
	-moz-transition: $transValue;
	transition: $transValue;
}
@mixin transform ($transValue) {
	-o-transform: $transValue;
	-webkit-transform: $transValue;
	-moz-transform: $transValue;
	transform: $transValue;
}
@mixin filter ($filterValue) {
	-o-filter: $filterValue;
	-webkit-filter: $filterValue;
	-moz-filter: $filterValue;
	filter: $filterValue;
}
@mixin transitionDelay ($transValue) {
	-o-transition-delay: $transValue;
	-webkit-transition-delay: $transValue;
	-moz-transition-delay: $transValue;
	transition-delay: $transValue;
}
@mixin writingMode ($transValue) {
	-o-writing-mode: $transValue;
	-webkit-writing-mode: $transValue;
	-moz-writing-mode: $transValue;
	writing-mode: $transValue;
}

.end {
	padding: 4rem 0;
}
 
.minbg {
	background: none !important;
	background-size: none !important;
	mask-image: none !important;
	-webkit-mask-size: none !important;
}

#schedule{
	margin: -3vh auto 0;
	text-align: center;
	position: relative;
	z-index: 13;
	background: url(../assets/bg-type05.png) no-repeat;
	background-position: center;
	background-size: cover;
    mask-image: url(../assets/bg-type05-mask.png);
                -webkit-mask-image:url(../assets/bg-type05-mask.png);
                -webkit-mask-size: cover;
		h1 {
			font-family: 'Italianno', cursive;
			color: $maintint;
			font-size: calc(3.4rem + ((1vw - 0.64rem) * 2.1429));
			text-align: center;
			padding-top: 2rem;
			opacity: 0;/*default*/
		span::before {
			content: '';
			background: url(../assets/flower-type04.png);
			background-repeat: no-repeat;
			background-size: cover;
			display: inline-block;
			width: 34px;
			height: 41px; 
			vertical-align: sub;
			margin: 0 .5rem;
		}
		span:nth-of-type(2)::before {
			transform: scale(-1, 1);
		}		
	}
}

.dateScheduleWrapper {
	margin: 2vh auto 0;
}

.dateSchedule {
	text-align: initial;
    display: flex;
    display: -webkit-flex;
    flex-direction: inherit;
    -webkit-flex-direction: column !important;
    overflow-x: hidden;
	margin-bottom: -7vh;
		li {
			width: 100%;
			position:relative;
			margin: 0 auto;
			border-top: solid 1px $maintint;
			background: url(../assets/event-frame01.png),url(../assets/event-frame02.png),url(../assets/event-frame03.png),url(../assets/event-frame04.png);
			background-size: 11%;
			background-repeat: no-repeat;
			background-position: 0 0, 100% 0, 0 100%, 100% 100%;
			box-sizing: border-box;	
			line-height: 1.8;
			padding: 32px 2vw;
			background-color: #fff;
			&:last-of-type {
			border-bottom: solid 1px $maintint;
			}
		div:first-of-type {
			width: 50%;
			float: left;
			padding-left: 16px;
			h3 {
				font-size: 1.45rem;
				&::after {
					content: '会場';
					font-size: 1.1rem;
					padding-left: 3px;
				}
			}	
			p {
				font-feature-settings: 'palt' 1;
					&:first-of-type {
						font-size: 1.1rem;
						margin-bottom: 4px;
						letter-spacing: 0;
					}
					&:first-of-type time {
						font-size: 1.6rem;
						padding-right: 4px;
						letter-spacing: .3px;
					}
					&:nth-of-type(2) {
						line-height: 1.6;
						font-size: 1.2rem;
					}	
			}
		}
		div:nth-of-type(2) {
			width: 45%;
			float: right;
			padding-right: 16px;
			/* add */
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			z-index: 20;
			a:nth-last-of-type(2) {
			position: relative;
			z-index: 9999;
			@include radius(5px);
			padding: .6rem 0;
			border: 1px #cbc560 solid;
			text-shadow: 0px 1px 0px white;
			background: -moz-linear-gradient(top,  #fdf1cb 0%, #e9d597 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top,  #fdf1cb 0%,#e9d597 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom,  #fdf1cb 0%,#e9d597 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fdf1cb', endColorstr='#e9d597',GradientType=0 ); /* IE6-9 */
			display: block;
			margin-bottom: 1.2rem;
				&:hover {
					outline: 0;
					@include transition(all 0.3s ease-in);
					opacity: 0.5;
				}
				p {
				font-size: 1.3rem;
				color: #7e720e;
				@include transform(rotate(0.1deg));
				&::before {
					content: '';
					position: absolute;
					background: url(../assets/bg-light01.png);
					background-repeat: no-repeat;
					height: 30px;
					width: 30px;
					background-size: 100%;
					left: -.6rem;
					top: -1rem;
				}
				&::after {
					content: '';
					position: absolute;
					background: url(../assets/bg-light02.png);
					background-repeat: no-repeat;
					height: 30px;
					width: 30px;
					background-size: 100%;
					right: -1rem;
					bottom: -1rem;
				}
				}
			}
			a {
				display: block;
				text-align: center;
			}
			a:last-of-type p {
				position: relative;
				z-index: 90;
				font-size: 1rem;
				text-decoration: underline;
			}
		}

		a:last-of-type p {
			text-decoration: underline;
			font-size: calc(1.72rem + ((1vw - 0.64rem) * 2.1429));
		}
	}
}

@media only screen and (min-width:500px) {
	.dateSchedule li div:first-of-type {
		padding-left: 40px;
	}
	.dateSchedule li div:nth-of-type(2) {
		padding-right: 40px;
	}
}
@media only screen and (min-width:600px) {
.dateSchedule li div:nth-of-type(2) {
	width: 38%;
}
.dateSchedule li{
background-size: 50px;
}
}
@media only screen and (min-width: 768px) {
	#schedule{
	margin: -1rem auto -20rem;
	background-image: none;
	position: relative;
    z-index: 20;
	    padding-top: 2rem;
	h1 {
			font-size: 3rem;
		}
	}
	.dateSchedule {
	    width: 64%;
    margin: 0 auto;
	margin-bottom: 24px;
	}
}
@media only screen and (min-width: 800px) {
	.dateSchedule {
		width: 83%;
	}
}

@media only screen and (min-width: 1024px) {
	.dateSchedule {
		width: 60%;
	}
}
@media only screen and (min-width:1200px) {
		
	.dateSchedule {
		width: 70%;
		display: flex;
		display: -webkit-flex;
		-webkit-justify-content: space-between;
		justify-content: space-between;
		flex-direction: initial !important;
		-webkit-flex-direction: initial !important;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		li {
			width: 50%;
			box-sizing: border-box;
			border-top: none;
			border-bottom: solid 1px #b39f47;
			margin: 0;
		}
	}
	.dateSchedule li:nth-of-type(1) {
		border-top: solid 1px #b39f47;
	}
	.dateSchedule li:nth-of-type(2) {
		border-top: solid 1px #b39f47;
	}
	.dateSchedule li:nth-of-type(odd) div:nth-of-type(2) {
		margin-right: 16px;
	}
	.dateSchedule li:nth-of-type(even) div:first-of-type {
		margin-left: 16px;
	}
	.dateSchedule li:nth-of-type(odd) {
		border-right: 1px solid $maintint;
	}
	.dateSchedule li div:nth-of-type(2) {
		padding-right: 0;
		right: 24px;
	}
	
	.dateSchedule li div:first-of-type {
		padding-left: 0;
	}
}
@media only screen and (min-width: 1700px) {
.dateSchedule {
    width: 1000px;
}
@media only screen and (min-width: 2000px) {
	#noveltyInfo div > figure {
		width: auto;
		height: 100%;
	}
	#noveltyInfo div>figure img {
		width: auto;
		height: 100%;
	}
}
}
</style>