<template>
		<section id="noveltyInfo">
			<p class="star03"><img src="../assets/star01.png" alt="star"></p>
			<p class="star04"><img src="../assets/star02.png" alt="star"></p>
			<p class="star05"><img src="../assets/star01.png" alt="star"></p>
			<div>
			<h1>Present<span></span>Information</h1>
			<figure><img src="../assets/novelty-info.png" alt="ノベルティ"></figure>
			<p>ご来場予約特典として、ベイビーアニマルシリーズの人気作[スナグルバニーズ]のクリアファイルと、フェアリーシリーズの傑作[シィリーシャルフレンズ]のアートカードをプレゼント。<br>
			どちらもオリジナル限定ノベルティです。<br>
			ぜひ、この機会にご来場ください。</p>
			</div>
		</section>
</template>
 
<script>
export default {
  data() {
    return {
    };
  },
  methods: {
    handleScroll() {
		var allHeight = window.innerHeight;
		var scroll = window.pageYOffset;
		var targetPosition10 = window.pageYOffset + document.querySelector('#noveltyInfo div h1').getBoundingClientRect().top;
		var targetPosition11 = window.pageYOffset + document.querySelector('#noveltyInfo div > figure').getBoundingClientRect().top;
	  
      if (scroll > targetPosition10 - allHeight) {
       document.querySelector('#noveltyInfo div h1').classList.add('galleryAni01');
      }
      if (scroll > targetPosition11 - allHeight) {
       document.querySelector('#noveltyInfo div > figure').classList.add('galleryAni02');
      }
    }
  },
  created: function() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy: function () {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>
<style lang="scss">
$main : #6e6307;
$maintint : #b39f47;
@mixin transform ($transValue) {
	-o-transform: $transValue;
	-webkit-transform: $transValue;
	-moz-transform: $transValue;
	transform: $transValue;
}
@mixin maincolorshadow {
	box-shadow:
		0 0 10px $maintint;
}
.star03 {
	position: absolute;
	width: 3rem;
	height: auto;
	top: 11vh;
    left: 35%;
	animation: opacityAnimation 2.6s infinite alternate;
	-webkit-animation-name: opacityAnimation;
	-webkit-animation-duration: 2.6s;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-direction: alternate;
	img {
		width: 100%;
	}
}
.star04 {
	position: absolute;
	width: 1.5rem;
	height: auto;
    top: 14vh;
    left: 35%;
	animation: opacityAnimation 2.6s .6s infinite alternate;
	-webkit-animation-name: opacityAnimation;
	-webkit-animation-duration: 2.6s;
	-webkit-animation-delay: .6s;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-direction: alternate;
	img {
		width: 100%;
	}
}

#noveltyInfo {
		position: relative;
		padding: 10vh 0 6vh;
		background: url(../assets/bg-type04.png) no-repeat;
		background-size: 100% 100%;
		background-position: center;
		overflow: hidden;
		background-size: cover;
		mask-image: url(../assets/bg-type04-mask.png);
					-webkit-mask-image:url(../assets/bg-type04-mask.png);
					-webkit-mask-size: contain;
		div{
			h1 {
				opacity: 0;/*default*/
				width: 40%;
				position: relative;
				font-family: 'Italianno', cursive;
				color: $maintint;
				font-size: calc(3.4rem + ((1vw - 0.64rem) * 2.1429));
				letter-spacing: .4vw;
				padding-left: 4vw;
				text-align: left;
				line-height: 0.9;
				float: left;
				&>span {
					display: block;
				}
				&::before,
				&::after {
					content: '';
					width: 44px;
					height: 18px;
					position: absolute;
					background: url(../assets/flower-type03.png);
					background-repeat: no-repeat;
					background-size: cover;
				}
			&::before {
				margin: -3rem 1rem;
			}
			&::after {
				right: 0;
				transform: scale(-1, -1);
				bottom: -2rem;
			}
		}
		&>figure {
			opacity: 0;/*default*/
			width: 50%;
			float: right;
			margin-top: -6vh;
			padding-right: 4vw;
			img {
				width: 100%;
			}
		}
		&>p {
			width: 92vw;
			margin: 3vh 4vw;
			float: left;
			line-height: 1.8;
			font-size: 3.2vw;
			font-size: calc(1.72rem + ((1vw - 0.64rem) * 2.1429));
		}
	}
}
@media only screen and (min-width:768px) {
.star03 {
width: 4rem;
    height: auto;
    top: 22vh;
    left: 65%;
}
.star04 {
width: 2rem;
    height: auto;
    top: 25vh;
    left: 64%;
}
#noveltyInfo {
	position: relative;
	padding: 10rem 0;
	margin-top: -8rem;
    background: url(../assets/bg-type04-pc.png) no-repeat;
    background-size: cover;
    background-position: center;
    mask-image: url(../assets/bg-type04-mask.png);
                -webkit-mask-image:url(../assets/bg-type04-mask.png);
                -webkit-mask-size: contain;
		div {
			width: 90%;
			margin: 0 auto;
			position: relative;
			overflow: hidden;
			h1 {
				opacity: 0;/*default*/
				margin: 4rem;
				padding-left: 0;
				letter-spacing: .2vw;
				margin: 4rem 0;
				padding-left: 0;
				width: 65%;
				text-align: center;
				
				&>span {
					display: inline;
				}
				&::before {
					margin: -3rem -3rem;
					vertical-align: top;
				}
				&::after {
					margin: -1rem -3rem;
				}
				&::before,
				&::after {
					position: inherit;
					display: inline-block;
				}
			}
			&>figure {
				width: 30%;
				margin-top: 0;
				padding-right: 0;
				position: absolute;
				bottom: 0;
				right: 0;
			}
			&::before {
				content: '';
				position: absolute;
				width: 65%;
				height: 100%;
				background-color: rgba(255,255,255,0.7);
				border: 1px solid #eee;
				left: 0;/*default*/
			}
			&>p {
				font-size: 1.1rem;
				width: 65%-4%;
				margin: 2%;
				position: relative;
				z-index: 10;
			}
		}
	}
}
@media only screen and (min-width:500px) {
	#noveltyInfo div h1 {
		font-size: 9vw;
	}
}
@media only screen and (min-width:600px) {
	#noveltyInfo div h1 {
		font-size: 8vw;
	}
}
@media only screen and (min-width: 768px) {
	#noveltyInfo div h1 {
		font-size: 3rem;
	}
}
@media only screen and (min-width:1024px) {
		#noveltyInfo div {
			width: 70%;
			}
}
@media only screen and (min-width:1500px) {
		#noveltyInfo div {
			    width: 1000px;
			}
}
</style>