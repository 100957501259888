<template>
		<section id="novelty">
				<h1><img src="../assets/logo.png"></h1>
				<h2>癒しのヴェールで世界を包む至高のアーティスト<span>日本で特別展を開催</span></h2>
			<div>
				<div>
				<h3>ご来場予約特典</h3><p>Webご予約者様限定で<br>オリジナルクリアファイルと<br>アートカードをプレゼント</p>
				<img src="../assets/novelty-frame.png" alt="プレゼント詳細">
				<img src="../assets/novelty-frame-pc.png" alt="プレゼント詳細">
				</div>
			</div>
			<figure><img src="../assets/novelty-pic.png" alt="プレゼント"></figure>
			<figure><img src="../assets/novelty-pic-pc.png" alt="プレゼント"></figure>
		</section>
</template>
 
<script>
export default {
  data () {
    return {
	}
  },
  methods: {
  },
  created: function() {
  },
  beforeDestroy: function () {
  }
}
</script>
<style lang="scss" scoped>
@mixin whiteshadow {
	text-shadow:
		0 0 4px #fff,
		0 0 4px #fff,
		0 0 4px #fff,
		0 0 4px #fff,
		0 0 4px #fff;
}

@media only screen and (min-width:768px) {
h1 img {
	opacity: 0;
	animation: opacityAnimation 2s 1s forwards;
	-webkit-animation-name: opacityAnimation;
	-webkit-animation-duration: 2s;
	-webkit-animation-delay: 1s;
	-webkit-animation-fill-mode: forwards;
}

h2 {
	opacity: 0;
	animation: opacityAnimation 1s 2s forwards;
	-webkit-animation-name: opacityAnimation;
	-webkit-animation-duration: 1s;
	-webkit-animation-delay: 2s;
	-webkit-animation-fill-mode: forwards;
}

h1 {
	margin-top: 3vh;
    img {
        width: 100%;
    }
}
h2 {
    @include whiteshadow;
    text-align: right;
    font-size: calc(.7rem + ((1vw - 0.64rem) * 2.1429));
    white-space: nowrap;
    margin: 2vw 0;
    letter-spacing: .1rem;
    width: 100%;
	padding-bottom:20%;
    span {
        text-align: right;
        display: block;
        width: 100%;
        line-height: 2;
    }
}	
}
@media only screen and (min-width:1200px) {
	h2 {
		padding-bottom: 13%;
	}
}
@media only screen and (min-width:1350px) {
    h2 {
        font-size: 1.6rem;
    }
}
@media only screen and (min-width:1499px) {
	h2 {
		font-size: 1.4rem;
	}
	.active {
		height: 630px;
	}
}
@media only screen and (min-width:1800px) {
	.active {
		height: 700px;
	}
}
@media only screen and (min-width:2000px) {
	h2 {
		text-align: left;
		span {
			text-align: left;
			margin: 0;
		}
	}
}
@media only screen and (min-width:2200px) {
	.active {
		height: 800px;
	}
}
@media only screen and (min-width:2650px) {
	.active {
		height: 1000px;
	}
}
</style>
<style lang="scss">
$main : #6e6307;
$maintint : #b39f47;
@mixin transform ($transValue) {
	-o-transform: $transValue;
	-webkit-transform: $transValue;
	-moz-transform: $transValue;
	transform: $transValue;
}
.active {
	height: 550px;
	h1 {
	margin-top: 0;
	}
}

@keyframes moveAnimation {
	0%{
		margin-bottom: 6rem;
	}
	100%{
	opacity: 1;
		margin-bottom: 0;
	}
}
@-webkit-keyframes moveAnimation {
	0%{
		margin-bottom: 6rem;
	}
	100%{
	opacity: 1;
		margin-bottom: 0;
	}
}
@-moz-keyframes moveAnimation {
	0%{
		margin-bottom: 6rem;
	}
	100%{
	opacity: 1;
		margin-bottom: 0;
	}
}

#novelty div div:first-of-type img {
	opacity: 0;
	animation: opacityAnimation .8s 2.3s forwards;
	-webkit-animation-name: opacityAnimation;
	-webkit-animation-duration: .8s;
	-webkit-animation-delay: 2.3s;
	-webkit-animation-fill-mode: forwards;
}
#novelty div div:first-of-type h3{
	opacity: 0;
	animation: opacityAnimation .8s 3s forwards;
	-webkit-animation-name: opacityAnimation;
	-webkit-animation-duration: .8s;
	-webkit-animation-delay: 3s;
	-webkit-animation-fill-mode: forwards;
}
#novelty div div:first-of-type p {
	opacity: 0;
	animation: opacityAnimation .8s 3.5s forwards;
	-webkit-animation-name: opacityAnimation;
	-webkit-animation-duration: .8s;
	-webkit-animation-delay: 3.5s;
	-webkit-animation-fill-mode: forwards;
}

#novelty figure img {
	opacity: 0;
	animation: moveAnimation 2s 4.2s forwards;
	-webkit-animation-name: moveAnimation;
	-webkit-animation-duration: 2s;
	-webkit-animation-delay: 4.2s;
	-webkit-animation-fill-mode: forwards;
}

#novelty {
	width: 100%;
	position: absolute;
	bottom: -160px;
	div {
		width: 68%;
		position: relative;
		div {
			&:first-of-type {
				width: 100%;
				margin-left: -10%;
				float: left;
		h3 {
			font-size: calc(2.1rem + ((1vw - 0.64rem) * 2.1429));
			letter-spacing: .2rem;
			top: 18vw;
			position: absolute;
			left: 0;
			right: 0;
			text-align: center;
			z-index: 2;
			&::after {
				content: '';
				display: block;
				background: url(../assets/novelty-line.png) no-repeat;
				margin: 2vw auto;
				height: 10px;
				width: 55%;
				background-size: 90%;
				background-position: center;
					}
			}
		p {
			position: absolute;
			top: 36vw;
			z-index: 2;
			left: 0;
			right: 0;
			text-align: center;
			font-size: calc(1.7rem + ((1vw - 0.64rem) * 2.1429));
			line-height: 1.7;
		}
        img {
            width: 100%;
        }
		}
	}
	}
	figure {
        width: 50%;
        right: 0;
        position: absolute;
        top: 50%;
        @include transform(translateY(-50%));
		img {
			width: 100%;
		}
	}
}
@media only screen and (min-width:550px) {
	#novelty div div:first-of-type {
		width: 80%;
		margin-left: -4%;
		h3 {
			top: 15vw;
		}
		p {
			top: 30vw;
			font-size: 2.5vw;
		}
	}
	#novelty figure {
		width: 46%;
	}

}
@media screen and (orientation: landscape) {
		#novelty div div:first-of-type h3 {
		font-size: 3.5vw;
	}
}
@media only screen and (min-width:768px) {
	header {
		#novelty {
			width: 35vw;
			top: 50%;
			@include transform(translateY(-50%));
			left: 2vw;
			position: absolute;
			div {
				width: 84%;
				margin: 0 auto;
				div {
					&:first-of-type {
						width: 100%;
						margin-left: 0;
						h3 {
							font-size: 1.5vw;
							top: 17%;
							&::after {
								margin: .5vw auto 0;
							}
						}
						p {
							font-size: 1.2vw;
							line-height: 1.5;
							top: 68%;
						}
					}
				}
			}
			figure {
				width: 40%;
				margin: 0 auto;
				position: initial;
			}
		}
	}
}
@media only screen and (min-width:1100px) {
	header {
		#novelty {
			div {
				div {
					&:first-of-type {
						h3 {
							font-size: 1.6rem;
						}
						p {
							line-height: 1.5;
						}
					}
				}
			}
		}
	}
}

@media only screen and (min-width:1200px) {
	header {
		#novelty {
			left: 5vw;
			div {
				width: 80%;
				}
			}
	}
}

@media only screen and (min-width:1350px) {
	header {
		#novelty {
			width: 33vw;
				div {
					width: 84%;
					div:first-of-type h3 {
						font-size: 2rem;
					}
				}
			}
	}
}
@media only screen and (min-width:1499px) {
	header {
		#novelty {
			width: 26vw;
				div {
					width: 84%;
					div:first-of-type h3 {
						font-size: 1.6rem; 
					}
					div:first-of-type p {
						font-size: 1.2rem; 
					}
				}
			}
	}
}
@media only screen and (min-width:1750px) {
	header #novelty div {
		width: 77%;
	}
}

</style>