<template>
		<section id="artist">
			<p class="star01"><img src="../assets/star01.png" alt="star"></p>
			<p class="star02"><img src="../assets/star02.png" alt="star"></p>
			<p class="star07"><img src="../assets/star01.png" alt="star"></p>
			<figure>
				<img src="../assets/kirk-pic.jpg" alt="kirk・reinert">
			</figure>
			<div>
				<h1>カーク・レイナート</h1>
				<h2>空にたなびくオーロラを癒しのヴェールに変え、世界を包むヒーリングアート界No.1アーティスト</h2>
				<p>"目に捉えることができないが、確かに存在するものを有形とする。"<br>
					カークは、生まれたてのアニマルたちのピュアな一瞬を描く「ベイビーアニマルシリーズ」と、神話で描かれる幻獣や妖精たちを表現する「フェアリーシリーズ」で、多くの人たちに‘癒し’を届けるアーティスト。<br>
					彼は、私たちが見ることができない、世界の輪郭を浮かび上がらせ、色を与える、唯一の存在です。<br>
					生命の鼓動を宿した、究極のヒーリングアート。<br>
					心が光で満ち溢れる瞬間を、会場で体感してみてください。</p>
			</div>
		</section>
</template>
 
<script>
export default {
  data() {
    return {
      targetNormal: 0
    };
  },
  methods: {
    handleScroll() {
		var allHeight = window.innerHeight;
		var scroll = window.pageYOffset;
		var targetPosition01 = window.pageYOffset + document.querySelector('#artist figure img').getBoundingClientRect().top;
		var targetPosition02 = window.pageYOffset + document.querySelector('#artist > div h1').getBoundingClientRect().top;
		var targetPosition03 = window.pageYOffset + document.querySelector('#artist figure').getBoundingClientRect().top;
		var targetPositionPC01 = window.pageYOffset + document.querySelector('#artist > div').getBoundingClientRect().top;
      this.targetNormal = targetPosition01;
	  
      if (scroll > targetPosition01 - allHeight) {
       document.querySelector('#artist figure img').classList.add('artistAni01');
      }
      if (scroll > targetPosition02 - allHeight) {
       document.querySelector('#artist > div h1').classList.add('artistAni02');
      }
      if (scroll > targetPosition03 - allHeight) {
       document.querySelector('#artist figure').classList.add('artistAni03');
      }
      if (scroll > targetPositionPC01 - allHeight) {
       document.querySelector('#artist > div').classList.add('artistAni04');
      }
    }
  },
  created: function() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy: function () {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style lang="scss">
$main : #6e6307;
$maintint : #b39f47;
@mixin transform ($transValue) {
	-o-transform: $transValue;
	-webkit-transform: $transValue;
	-moz-transform: $transValue;
	transform: $transValue;
}
@mixin maincolorshadow {
	box-shadow:
		0 0 10px $maintint;
}

.star01 {
	position: absolute;
	width: 3rem;
	height: auto;
	top: 20rem+5rem;
	left: 10%;
	animation: opacityAnimation 2s infinite alternate;
	-webkit-animation-name: opacityAnimation;
	-webkit-animation-duration: 2s;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-direction: alternate;
	img {
		width: 100%;
	}
}
.star02 {
	position: absolute;
	width: 1.5rem;
	height: auto;
	top: 20rem+7rem;
	left: 15%;
	animation: opacityAnimation 2.3s .3s infinite alternate;
	-webkit-animation-name: opacityAnimation;
	-webkit-animation-duration: 2.3s;
	-webkit-animation-delay: .3s;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-direction: alternate;
	img {
		width: 100%;
	}
}
.star07 {
	position: absolute;
	width: 2rem;
	height: auto;
	top: 20rem+.5rem;
	left: 73%;
	animation: opacityAnimation 2.3s .3s infinite alternate;
	-webkit-animation-name: opacityAnimation;
	-webkit-animation-duration: 2.3s;
	-webkit-animation-delay: .3s;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-direction: alternate;
	img {
		width: 100%;
	}
}

.artistAni01 {
	animation: opacityAnimation 2s 1s forwards;
	-webkit-animation-name: opacityAnimation;
	-webkit-animation-duration: 2s;
	-webkit-animation-delay: 1s;
	-webkit-animation-fill-mode: forwards;
}
.artistAni03::before {
	animation: opacityAnimation 1.5s .6s forwards;
	-webkit-animation-name: opacityAnimation;
	-webkit-animation-duration: 1.5s;
	-webkit-animation-delay: .6s;
	-webkit-animation-fill-mode: forwards;
}

@media only screen and (max-width:767px) {
.artistAni02 {
	animation: artistSlideAnimation 2s 1.6s forwards;
	-webkit-animation-name: artistSlideAnimation;
	-webkit-animation-duration: 2s;
	-webkit-animation-delay: 1.6s;
	-webkit-animation-fill-mode: forwards;
}
@keyframes artistSlideAnimation {
	0%{
		padding-left: 42%;
	}
	100%{
		opacity: 1;
		padding-left: 33%;
	}
}
@-webkit-keyframes artistSlideAnimation {
	0%{
		padding-left: 42%;
	}
	100%{
		opacity: 1;
		padding-left: 33%;
	}
}
@-moz-keyframes artistSlideAnimation {
	0%{
		padding-left: 42%;
	}
	100%{
		opacity: 1;
		padding-left: 33%;
	}
}
}
@media only screen and (min-width:768px) {
#artist > div h1 {
	opacity: 1!important;
}
.artistAni04 {
	opacity: 0;
	animation: artistSlideAnimation 1.5s 1.5s forwards;
	-webkit-animation-name: artistSlideAnimation;
	-webkit-animation-duration: 1.5s;
	-webkit-animation-delay: 1.5s;
	-webkit-animation-fill-mode: forwards;
}
@keyframes artistSlideAnimation {
	0%{
		opacity: 0;
		right: -5%;
	}
	100%{
		opacity: 1;
		right: 0;
	}
}
@-webkit-keyframes artistSlideAnimation {
	0%{
		opacity: 0;
		right: -5%;
	}
	100%{
		opacity: 1;
		right: 0;
	}
}
@-moz-keyframes artistSlideAnimation {
	0%{
		opacity: 0;
		right: -5%;
	}
	100%{
		opacity: 1;
		right: 0;
	}
}
}

#artist {
    padding-top: 20rem;
    background: url(../assets/bg-type02.png) no-repeat;
    background-size: 100%;
    background-position: 0 13rem;
	position: relative;
	figure {
		width: 40%;
		margin: 0 auto;
		position: relative;
		&::before {
			opacity: 0;/*default*/
			width: 100%;
			height: 112%+6%;
			content: 'Artist';
			text-align: center;
			font-family: 'Italianno', cursive;
			writing-mode: vertical-lr;
			-ms-writing-mode: lr-tb;
			position: absolute;
			top: 50%;
    		transform: translateY(-50%);
			left: -15.5vw;
			background: url(../assets/flower-type01.png),url(../assets/flower-type01-02.png);
			background-repeat: no-repeat;
			background-size: 29%;
			background-position: 2.5rem top, 2.5rem bottom;
			font-size: calc(3.8rem + ((1vw - 0.64rem) * 2.1429));
			z-index: 999;
			letter-spacing: .8vw;
			color: $maintint;
		}
		img {
			opacity: 0;/*default*/
			width: 100%;
			border: 1px #fff solid; 
			@include maincolorshadow;
		}
	}
	&>div {
		width: 100%;
		padding: 0px 6vw;
		h1 {
			opacity: 0;/*default*/
			font-size: 4.8vw;
			margin: 1rem 0 0;
			&::after{
				content: 'Kirk Reinert';
				font-family: 'Italianno', cursive;
				color: $maintint;
				-webkit-filter: blur(2px);
				filter: blur(2px);
				font-size: 10vw;
				opacity: .3;
				display: block;
				margin-top: -2rem;
				padding-left: 12.5vw;
				white-space: nowrap;
				overflow: hidden;
			}
		}
		&>p {
			margin: 2vw 0;
			line-height: 1.8;
		}
	}
}
@media only screen and (min-width:500px) {
	#artist {
		figure {
			&::before {
				font-size: 9vw;
			}
		}
	}
}
@media only screen and (min-width:600px) {
	#artist {
		figure {
			&::before {
				background-size: 23%;
				font-size: 8vw;
				left: -12.5vw;
			}
		}
		&> div h1::after {
			font-size: 8vw;
		}
	}
}

@media only screen and (min-width:768px) {
.star01 {
    width: 5rem;
    top: 15%;
    left: 30%;
}
.star02 {
width: 2.5rem;
    top: 19%;
    left: 34%;
}
#artist {
	width: 100%;
	padding: 8rem 0 10rem;
	overflow: hidden;
	position: relative;
	background-position: 0 0;
	background: url(../assets/bg-type02-pc.png) no-repeat;
	background-size: cover;
	background-position: center top;
	figure {
	width: 27%;
    float: left;
	padding: 4% 1rem;
	margin-bottom: 3%;
	z-index: 10;
		&::before {
			height: auto;
			background: url(../assets/flower-type01-pc.png),url(../assets/flower-type01-pc02.png);
			background-repeat: no-repeat;
			background-size: 20%;
			writing-mode: inherit;
			padding-top: 0;
			top: 1rem;
			background-size: 17%;
			background-position: left 100%, right 100%;
			font-size: 3rem;
    		letter-spacing: .2rem;
			left: 0;
			right: 0;
			text-align: center;
		}
	}
	&>div {
		opacity: 0;/*default*/
		width: 77%;
		position: absolute;
		margin: 0 2% 0 1%;
		background-color: rgba(255,255,255,0.7);
		right: 0%;
		top: 12rem;
		padding: .5rem .5rem .5rem 4rem;
		border: 1px solid #eee;
			h1 {
				font-size: 1.55rem;
				padding-left: 0;
					&::after {
					font-size: 3rem;
					padding-left: 13rem;
					margin: -2.5rem auto 1rem;
					}
			}
			h2 {
				font-size: 1.2rem;
				margin-top: -2rem;
			}
			&>p {
				font-size: 1.1rem;
				margin: .5rem 0 0;
			}
	}
}
}
@media only screen and (min-width:800px) {
#artist {
	&>div {
	padding: 1rem 1rem 1rem 4rem;
		}
}
}
@media only screen and (min-width: 870px){
#artist > div {
    padding: 1rem 1rem 1rem 5rem;
}
}
@media only screen and (min-width: 880px){
#artist figure {
padding: 4% 3%;
}
#artist > div {
top: 14rem;
    padding: 2rem 2rem 2rem 6rem;
}
#artist figure {
margin-bottom: 5%;
}
}
@media only screen and (min-width:1000px) {
#artist {
	figure {
    padding: 4% 3%;
	margin-bottom: 0;
		&::before {
			top: 2rem;
		}
	}
	&>div {
		top: 14rem;
		padding: 2rem 2rem 2rem 6rem;
			h1 {
				font-size: 2rem;
					&::after {
					font-size: 3.5rem;
					padding-left: 20rem;
					margin: -2rem auto 1rem;
					}
			}
			h2 {
				font-size: 1.3rem;
			}
			&>p {
				margin: 1.5rem 0 0;
			}
	}
}
}
@media only screen and (min-width:1100px) {
	#artist {
		padding: 8rem 5% 15rem;
		background: url(../assets/bg-type02-pc02.png) no-repeat;
		background-size: cover;
		background-position: center top;
		&>div {
			margin: 0 5% 0 1%;
			padding: 2rem 2rem 2rem 8rem;
		}
		figure {
			width: 24%;
			padding: 4% 3%;
		}
	}
}

@media only screen and (min-width:1200px) {
	#artist {
		padding: 8rem 5%+4% 15rem;
		&>div {
			width: 77%-4%;
			margin: 0 5%+4% 0 1%;
			padding: 2rem 2rem 2rem 8rem+4rem;
		}
	}
}

@media only screen and (min-width:1400px) {
	#artist {
		background: url(../assets/bg-type02-pc03.png) no-repeat;
		background-size: cover;
		background-position: center;
		padding: 8rem 5%+4%+4% 15rem;
		&>div {
			width: 77%-4%-4%;
			margin: 0 5%+4%+4% 0 1%;
			padding: 2rem 2rem 2rem 8rem+4rem+4rem;
		}
	}
}
@media only screen and (min-width:1600px) {
	#artist {
		padding: 8rem 5%+4%+4%+4% 15rem;
		&>div {
			width: 61%;
			margin: 0 5%+4%+4%+4% 0 1%;
			padding: 2rem 2rem 2rem 10%;
		}
	}
}
@media only screen and (min-width:2000px) {
	#artist > div {
		top: 50%;
		transform: translateY(-50%);
	}
}

@media only screen and (min-width:1024px) and (max-height:420px) {
	#artist {
		margin-top: 20%;
	}
}
@media only screen and (min-width:1024px) and (min-height:421px) {
	#artist {
		margin-top: 15%;
	}
}
@media only screen and (min-width:1024px) and (min-height:551px) {
	#artist {
		margin-top: 12%;
	}
}
@media only screen and (min-width:1024px) and (min-height:651px) {
	#artist {
		margin-top: 8%;
	}
}
@media only screen and (min-width:1024px) and (min-height:801px) {
	#artist {
		margin-top: 5%;
	}
}
@media only screen and (min-width:1024px) and (min-height:1001px) {
	#artist {
		margin-top: 0;
	}
}
</style>


